import { Link } from "react-router-dom";
import React, { useEffect, useState, useContext } from "react";
import { toast } from "react-toastify";

function ContactUs() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    comments: "",
  });
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        process.env.REACT_APP_FURL + "/api/contact/submit-contact",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );
      const data = await response.json();
      if (response.ok) {
        toast.success(data.message);
        setFormData({
          name: "",
          email: "",
          phone: "",
          subject: "",
          comments: "",
        });
      } else {
        toast.error("Message submission failed");
      }
    } catch (error) {
      console.error("Error submitting contact:", error);
      toast.error("Internal server error");
    }
  };
  return (
    <section className="bd-contact-faq pt-120" id="contact">
      <div className="container">
        <div className="row wow fadeInUp" data-wow-delay=".3s">
          <div className="col-lg-6">
            <div className="bd-contact__wrapper mb-120">
              <div className="bd-section__title mb-50">
                <span className="bd__subtitle">Contact Us</span>
                <h2 className="bd__title-sm">
                  Give Us A Printing
                  <br />
                  Deal From Company
                </h2>
              </div>
              <div className="bd-contact__form">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="bd-single__input mb-20">
                        <input
                          type="text"
                          placeholder="Name"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="bd-single__input mb-20">
                        <input
                          type="text"
                          placeholder="Phone Number"
                          name="phone"
                          value={formData.phone}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="bd-single__input mb-20">
                        <input
                          type="email"
                          placeholder="Email Address"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="bd-single__input mb-20">
                        <input
                          type="text"
                          placeholder="Subject"
                          name="subject"
                          value={formData.subject}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="bd-single__input mb-20">
                        <textarea
                          className="form-control"
                          placeholder="Description"
                          name="comments"
                          value={formData.comments}
                          onChange={handleChange}
                          spellCheck="false"
                          required
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="bd-single__input">
                        <button type="submit" className="theme-btn">
                          Make An Order{" "}
                          <i className="fal fa-arrow-alt-right"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="bd-faq__wrapper bd-faq__padd2 mb-120">
              <div className="bd-section__title mb-50">
                <span className="bd__subtitle">Faq</span>
                <h2 className="bd__title-sm">
                  Our Experience Spans
                  <br /> Every Industry
                </h2>
              </div>
              <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      What file formats do you accept for printing?
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p>
                        We accept a variety of file formats, including PDF,
                        JPEG, PNG, AI, PSD, and EPS. Our experienced team can
                        also assist you with file preparation if needed.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      What is the turnaround time for printing orders?
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p>
                        Our turnaround time depends on the complexity and size
                        of the project. We strive to complete orders promptly
                        while maintaining the highest quality standards. Please
                        contact us for specific turnaround times for your
                        project.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      What types of printing services do you offer?
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p>
                        We offer a wide range of printing services, including
                        business cards, flyers, brochures, posters, banners,
                        signage, stationery, promotional materials, and much
                        more.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactUs;
