import React, { useState } from "react";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";

function Checkout() {
  const [number, setNumber] = useState("");
  const [otpSms, setOtpSms] = useState("");
  const [otpSent, setOtpSent] = useState(false);

  const blockInvalidChar = (e) =>
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

  const handleNumberChange = (e) => {
    const inputValue = e.target.value;
    if (/^[0-9]{0,10}$/.test(inputValue) && !/\be\b/.test(inputValue)) {
      setNumber(inputValue);
    }
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    const res = await fetch(process.env.REACT_APP_FURL + "/api/auth/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ number }),
    });
    if (res.status === 200) {
      setOtpSent(true);
      toast.success("OTP sent successfully");
    } else {
      toast.error("Unable to send otp");
    }
  };

  const handleOTPSubmit = async (e) => {
    e.preventDefault();
    const res = await fetch(
      process.env.REACT_APP_FURL + "/api/auth/verify-login",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ number, otpSms }),
      }
    );
    if (res.status === 200) {
      const data = await res.json();
      toast.success("OTP verified successfully");
      Cookies.set("userId", data.userId);
      Cookies.set("token", data.userToken);
      setTimeout(() => {
        window.location.replace(`/shippingaddress/${data.userId}`);
      }, 1000);
    } else {
      toast.error("otp verification failed! ");
    }
  };
  return (
    <>
      <section
        className="page-title-area breadcrumb-spacing"
        data-background="/assets/img/banner/breadcrumb-img.jpg"
        style={{
          backgroundImage: 'url("/assets/img/banner/breadcrumb-img.jpg")',
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-9">
              <div className="page-title-wrapper">
                <h3 className="page-title mb-25">Checkout</h3>
                <div className="breadcrumb-menu">
                  <nav
                    aria-label="Breadcrumbs"
                    className="breadcrumb-trail breadcrumbs"
                  >
                    <ul className="trail-items">
                      <li className="trail-item trail-begin">
                        <a href="index.html">
                          <span>Home</span>
                        </a>
                      </li>
                      <li className="trail-item trail-end">
                        <span>Checkout</span>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="content-inner shop-account">
        <div className="container">
          <div className="row">
            <div className="col-lg-3"></div>
            <div className="col-lg-6">
              <div className="card overflow-hidden mt-5">
                <div
                  className="text-center bg-primary position-relative"
                  style={{
                    background:
                      "linear-gradient(90.07deg, #892f86 32.43%, #3d2c88 58.79%, #1a133d 105.32%)",
                    borderColor: "#682e87",
                  }}
                >
                  <div className="position-relative pt-4 py-5 mb-1">
                    <h5 className="text-white">Checkout</h5>
                    <p className="mb-0 fs-14" style={{ color: "#fff" }}>
                      Sign in to Continue your purchase
                    </p>
                  </div>
                </div>
                <div className="card-body position-relative">
                  <div className="p-4 mt-n5 bg-white  pb-0 mt-2 mb-5">
                    {!otpSent ? (
                      <form onSubmit={handleLoginSubmit}>
                        <div className="mb-3">
                          <label
                            className="fs-14 mb-2 text-dark"
                            htmlFor="phoneNumber"
                          >
                            Mobile Number <span className="text-danger">*</span>
                          </label>
                          <div className="input-group">
                            <span className="input-group-text">+91</span>
                            <input
                              type="number"
                              className="form-control"
                              id="phoneNumber"
                              name="phoneNumber"
                              placeholder="Enter phoneNumber"
                              value={number}
                              // onChange={(e) => setNumber(e.target.value)}
                              onKeyDown={blockInvalidChar}
                              onChange={handleNumberChange}
                            />
                          </div>
                        </div>
                        <div className="mt-4">
                          <button
                            className="btn btn-primary w-100"
                            type="submit"
                            style={{
                              background:
                                "linear-gradient(90.07deg, #892f86 32.43%, #3d2c88 58.79%, #1a133d 105.32%)",
                              borderColor: "#682e87",
                            }}
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    ) : (
                      <form onSubmit={handleOTPSubmit}>
                        <div className="mb-3">
                          <label className="fs-14 mb-2 text-dark" htmlFor="otp">
                            Enter Otp <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="otp"
                            name="otp"
                            placeholder="Enter otp"
                            value={otpSms}
                            onChange={(e) => setOtpSms(e.target.value)}
                          />
                        </div>
                        <div className="mt-4">
                          <button
                            className="btn btn-primary w-100"
                            type="submit"
                            style={{
                              background:
                                "linear-gradient(90.07deg, #892f86 32.43%, #3d2c88 58.79%, #1a133d 105.32%)",
                              borderColor: "#682e87",
                            }}
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3"></div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Checkout;
