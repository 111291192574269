import React from "react";

function HomeBanner() {
  return (
    <div id="home" className="banner-area banner-area1 pos-rel white-bg-2">
      <div className="swiper-container">
        <div className="swiper-wrapper">
          <div className="swiper-slide">
            <div className="single-banner single-banner-2 banner-970 d-flex align-items-center pos-rel">
              <div className="container pos-rel">
                <div className="row align-items-center">
                  <div className="col-xl-6 col-lg-7">
                    <div
                      className="banner-content banner-content2 pt-40"
                      data-animation="fadeInUp"
                      data-delay=".5s"
                    >
                      <div className="banner-meta-text theme-color">
                        <span>Welcome To Our Company</span>
                      </div>
                      <h1
                        className="banner-title black-color"
                        data-animation="fadeInUp"
                        data-delay=".7s"
                      >
                        First in Quality
                        <br />
                        Printing Solutions
                      </h1>
                      <p
                        className="banner-text"
                        data-animation="fadeInUp"
                        data-delay=".9s"
                      >
                        Welcome to A-FAST PRINT, your top-quality printing
                        destination. With over two decades of experience, we
                        pioneer quality solutions, ensuring every project
                        receives unmatched attention to detail. Experience our
                        passion for excellence today
                      </p>
                      <div
                        className="banner-btn mt-50"
                        data-animation="fadeInUp"
                        data-delay="1.1s"
                      >
                        <a className="theme-btn" href="#contact">
                          Make An Order{" "}
                          <i className="fal fa-arrow-alt-right"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-5 d-xl-block">
                    <div
                      className="banner-img"
                      data-animation="fadeInUp"
                      data-delay="1.1s"
                    >
                      <img src="/assets/img/banner/banner-2.png" alt="Banner" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeBanner;
