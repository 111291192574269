import React from "react";

function Loader() {
  return (
    <div id="loading">
      <div id="loading-center">
        <div id="loading-center-absolute">
          <div className="loading-icon text-center d-flex flex-column align-items-center justify-content-center">
            <img
              src="/assets/img/logo/logo-black.png"
              width="50%"
              alt="Printpress"
            />
            <img
              className="loading-logo"
              src="/assets/img/logo/preloader.svg"
              alt="img"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Loader;
