import React from "react";

import HomeBanner from "../components/HomeBanner";
import ServicesList from "../components/ServicesList";
import ServicesCard from "../components/ServicesCard";
import AboutUs from "../components/AboutUs";
import EmergencyBanner from "../components/EmergencyBanner";
import WorkProcess from "../components/WorkProcess";
import WorkPortfolio from "../components/WorkPortfolio";
import ContactUs from "../components/ContactUs";
// import Test from "../components/Test";

function Home() {
  return (
    <div>
      <main>
        <HomeBanner />
        <ServicesList />
        <ServicesCard />
        <AboutUs />
        <EmergencyBanner />
        <WorkProcess />
        <WorkPortfolio />
        <ContactUs />
        {/* <Test /> */}
      </main>
    </div>
  );
}

export default Home;
