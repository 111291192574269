import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { ShopContext } from "../context/shop-context";
import Loader from "../components/Loader";
function SubProductDesc() {
  const { products, addToCartCustom } = useContext(ShopContext);
  const params = useParams();
  const [product, setProduct] = useState({});

  const [loading, setLoading] = useState(false);
  const [activeProduct, setActiveProduct] = useState(null);

  const [quantity, setQuantity] = useState(1);
  const [packageQ, setPackageQ] = useState(1);
  const [itemId, setItemId] = useState("");
  const [itemName, setItemName] = useState("");
  const [itemPrice, setItemPrice] = useState("");

  const handleAddToCartCustom = (itemName, itemPrice, packageQ, quantity) => {
    addToCartCustom(itemId, itemName, itemPrice, quantity, packageQ);
    toast.success("Product added to your cart", { autoClose: 2000 });
  };

  useEffect(() => {
    setLoading(true);
    const productFound = products.find((prd) => prd._id === params.id);
    if (productFound) {
      setActiveProduct(productFound);
      setItemId(productFound._id);
    } else {
      setActiveProduct(null);
    }
    setLoading(false);
  }, [products, params.id]);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    comments: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        process.env.REACT_APP_FURL + "/api/contact/submit-contact",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );
      const data = await response.json();
      if (response.ok) {
        toast.success(data.message);
        setFormData({
          name: "",
          email: "",
          phone: "",
          subject: "",
          comments: "",
        });
      } else {
        toast.error("Message submission failed");
      }
    } catch (error) {
      console.error("Error submitting contact:", error);
      toast.error("Internal server error");
    }
  };

  if (loading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  if (!activeProduct) {
    return <div>Error 404</div>;
  }

  return (
    <>
      <section
        className="page-title-area breadcrumb-spacing"
        style={{
          backgroundImage: "url('/assets/img/banner/breadcrumb-img.jpg')",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-9">
              <div className="page-title-wrapper">
                <h3 className="page-title mb-25">Business Cards</h3>
                <div className="breadcrumb-menu">
                  <nav
                    aria-label="Breadcrumbs"
                    className="breadcrumb-trail breadcrumbs"
                  >
                    <ul className="trail-items">
                      <li className="trail-item trail-begin">
                        <a href="index.html">
                          <span>Home</span>
                        </a>
                      </li>
                      <li className="trail-item trail-end">
                        <span>Services</span>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>{" "}
      <section className="bd-details__area pt-120 pb-60">
        <div className="container">
          <div className="row">
            {activeProduct && (
              <div className="col-xl-8">
                <div className="bd-details__content mb-60">
                  <div className="bd-details__content--thum br-img-6 w-img mb-50">
                    <img
                      src={process.env.REACT_APP_FURL + activeProduct?.image[0]}
                      alt="services images"
                    />
                  </div>
                  <h4 className="bd__title-xs mb-15">{activeProduct.title}</h4>
                  <p className="mb-25">{activeProduct.desc}</p>
                  <h4 className="bd__title-xs mb-25">How We Work Here</h4>
                  <div className="row mb-25">
                    <div className="col-lg-4 col-sm-6">
                      <div className="bd-details__work white-bg-2 border-radius-6 text-center mb-20">
                        <div className="bd-details__work--icon mb-25">
                          <img src="/assets/img/work/work-1.png" alt="Work" />
                        </div>
                        <h4 className="bd-details__work--title mb-15">
                          Prepress operations encompass
                        </h4>
                        <p>
                          Quam parturient mi amet curae augue varius laoreet
                          vehicula non.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                      <div className="bd-details__work white-bg-2 border-radius-6 text-center mb-20">
                        <div className="bd-details__work--icon mb-25">
                          <img src="/assets/img/work/work-2.png" alt="Work" />
                        </div>
                        <h4 className="bd-details__work--title mb-15">
                          The image or printing area is raised
                        </h4>
                        <p>
                          Quam parturient mi amet curae augue varius laoreet
                          vehicula non.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                      <div className="bd-details__work white-bg-2 border-radius-6 text-center mb-20">
                        <div className="bd-details__work--icon mb-25">
                          <img src="/assets/img/work/work-3.png" alt="Work" />
                        </div>
                        <h4 className="bd-details__work--title mb-15">
                          The intaglio process, the nonprinting
                        </h4>
                        <p>
                          Quam parturient mi amet curae augue varius laoreet
                          vehicula non.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-15">
                    <div className="col-md-6">
                      <div className="bd-details__content--thum bd-services-details-img br-img-6 w-img mb-30">
                        <img
                          src={
                            process.env.REACT_APP_FURL + activeProduct?.image[1]
                          }
                          alt="service image"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="bd-design__guideline white-bg-2 border-radius-6 bd-design__guideline--padd mb-30">
                        <h2 className="bd__title-xs mb-25">Card Details</h2>
                        <ul>
                          <li>
                            Paper Type<span>{activeProduct.papertype}</span>
                          </li>
                          <li>
                            Coating<span>{activeProduct.coating}</span>
                          </li>
                          <li>
                            Color
                            <span>{activeProduct.color}</span>
                          </li>
                          <li>
                            Quantities<span>{activeProduct.quantities}</span>
                          </li>
                          <li>
                            Sizes<span>{activeProduct.sizes}</span>
                          </li>
                          <li>
                            Finishing<span>{activeProduct.finishing}</span>
                          </li>
                          <li>
                            File Type<span>{activeProduct.filetype}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-20">
                    <div className="col-xl-3 col-lg-4 col-6 mb-20">
                      <div className="bd-postcard-item bd-hover-top white-bg-2 border-radius-6 text-center">
                        <h4 className="bd__title-xs">
                          $ {activeProduct.price1}
                        </h4>
                        <p>
                          50 Business Card <br />
                          Price
                        </p>{" "}
                        <br />
                        {/* <button
                          type="submit"
                          className="theme-btn"
                          onClick={() =>
                            handleAddToCartCustom(
                              activeProduct.title,
                              activeProduct.price1,
                              50,
                              1
                            )
                          }
                        >
                          Add to Cart
                        </button> */}
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-6 mb-20">
                      <div className="bd-postcard-item bd-hover-top white-bg-2 border-radius-6 text-center">
                        <h4 className="bd__title-xs">
                          $ {activeProduct.price2}
                        </h4>
                        <p>
                          250 Business Card <br />
                          Price
                        </p>
                        <br />
                        {/* <button
                          type="submit"
                          className="theme-btn"
                          onClick={() =>
                            handleAddToCartCustom(
                              activeProduct.title,
                              activeProduct.price1,
                              250,
                              1
                            )
                          }
                        >
                          Add to Cart
                        </button> */}
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-6 mb-20">
                      <div className="bd-postcard-item bd-hover-top white-bg-2 border-radius-6 text-center">
                        <h4 className="bd__title-xs">
                          $ {activeProduct.price3}
                        </h4>
                        <p>
                          500 Business Card <br />
                          Price
                        </p>
                        <br />
                        {/* <button
                          type="submit"
                          className="theme-btn"
                          onClick={() =>
                            handleAddToCartCustom(
                              activeProduct.title,
                              activeProduct.price1,
                              500,
                              1
                            )
                          }
                        >
                          Add to Cart
                        </button> */}
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-6 mb-20">
                      <div className="bd-postcard-item bd-hover-top white-bg-2 border-radius-6 text-center">
                        <h4 className="bd__title-xs">
                          $ {activeProduct.price4}
                        </h4>
                        <p>
                          1k Business Card
                          <br />
                          Price
                        </p>
                        <br />
                        {/* <button
                          type="submit"
                          className="theme-btn"
                          onClick={() =>
                            handleAddToCartCustom(
                              activeProduct.title,
                              activeProduct.price1,
                              1000,
                              1
                            )
                          }
                        >
                          Add to Cart
                        </button> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="col-xl-4">
              <div className="bd-details-sidebar mb-60">
                <div className="sidebar__widget white-bg-2 border-radius-6 mb-60">
                  <div className="sidebar-title mb-25">
                    <h4>Our Services</h4>
                  </div>
                  <ul className="sidebar__list">
                    <li>
                      <a href="#">
                        Brochure{" "}
                        <span className="icon">
                          <i className="fa-solid fa-arrow-right"></i>
                        </span>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        Postcards{" "}
                        <span className="icon">
                          <i className="fa-solid fa-arrow-right"></i>
                        </span>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        Invitation card
                        <span className="icon">
                          <i className="fa-solid fa-arrow-right"></i>
                        </span>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        Poster Printing{" "}
                        <span className="icon">
                          <i className="fa-solid fa-arrow-right"></i>
                        </span>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        T-Shirt Printing{" "}
                        <span className="icon">
                          <i className="fa-solid fa-arrow-right"></i>
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="sidebar__widget white-bg-2 border-radius-6">
                  <div className="sidebar-title mb-25">
                    <h4>Quick Contact</h4>
                  </div>
                  <div className="contact-form">
                    <form onSubmit={handleSubmit}>
                      <div className="bd-single__input mb-20">
                        <input
                          type="text"
                          placeholder="Name"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="bd-single__input mb-20">
                        <input
                          type="text"
                          placeholder="Phone Number"
                          name="phone"
                          value={formData.phone}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="bd-single__input mb-20">
                        <input
                          type="email"
                          placeholder="Email Address"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          required
                        />
                      </div>{" "}
                      <div className="bd-single__input mb-20">
                        <input
                          type="text"
                          placeholder="Subject"
                          name="subject"
                          value={formData.subject}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="bd-single__input mb-20">
                        <textarea
                          className="form-control"
                          placeholder="Message"
                          name="comments"
                          value={formData.comments}
                          onChange={handleChange}
                          spellCheck="false"
                          required
                        ></textarea>
                      </div>
                      <div className="bd-single__input">
                        <button type="submit" className="theme-btn">
                          Make An Order
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SubProductDesc;
