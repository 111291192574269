import React, { useEffect, useState, useContext } from "react"
import { useParams } from "react-router-dom"
import { Link } from "react-router-dom"

import Pagination from "../components/Pagination"
import Loader from "../components/Loader"
import { ShopContext } from "../context/shop-context"

function MainSubProducts() {
  let params = useParams()
  const { products, maincategory } = useContext(ShopContext)

  const [loading, setLoading] = useState(false)
  const [activeProducts, setActiveProducts] = useState([])

  useEffect(() => {
    setLoading(true)
    const productFound = maincategory.find((prd) => prd._id === params.id)

    if (productFound) {
      console.log(productFound)

      setActiveProducts(productFound.subproduct)
    } else {
      setActiveProducts([])
    }
    setLoading(false)
  }, [maincategory, params.id])

  if (loading) {
    return (
      <div>
        <Loader />
      </div>
    )
  }
  console.log(activeProducts)

  // if (!activeProducts.length) {
  //   return <div>Error 404</div>;
  // }

  return (
    <>
      <section
        className="page-title-area breadcrumb-spacing"
        data-background="/assets/img/banner/breadcrumb-img.jpg"
        style={{
          backgroundImage: 'url("/assets/img/banner/breadcrumb-img.jpg")',
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-9">
              <div className="page-title-wrapper">
                <h3 className="page-title mb-25">Printing Products</h3>
                <div className="breadcrumb-menu">
                  <nav
                    aria-label="Breadcrumbs"
                    className="breadcrumb-trail breadcrumbs"
                  >
                    <ul className="trail-items">
                      <li className="trail-item trail-begin">
                        <a href="index.html">
                          <span>Home</span>
                        </a>
                      </li>
                      <li className="trail-item trail-end">
                        <span>Services</span>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bd-blog grey-bg-2 pt-120 pb-130">
        <div className="container">
          <div
            className="row justify-content-center wow fadeInUp"
            data-wow-delay=".3s"
          ></div>
          <div className="bd-blog__wrapper2 grey-bg-2">
            <div className="row wow fadeInUp" data-wow-delay=".5s">
              {activeProducts.map((product) => (
                <div className="col-xl-4 col-md-6" key={product._id}>
                  <article>
                    <div className="bd-blog__item p-relative mb-40">
                      <Link to={`/sub-product-desc/${product._id}`}>
                        <div className="bd-blog__thum w-img">
                          <a>
                            <img
                              src={'https://api.afastprint.ca' + product?.image[0]}
                              alt="Blog"
                            />
                          </a>
                        </div>
                        <div className="bd-blog__content white-bg">
                          <div className="bd-blog__category">
                            <a href="businesscard.html">Our Services</a>
                          </div>
                          <h3 className="bd-blog__title mb-30">
                            <a href="businesscard.html">{product.title}</a>
                          </h3>
                          <p>{product.desc}</p>
                          <div className="bd-blog__btn">
                            <a href="businesscard.html">
                              read more{" "}
                              <i className="fal fa-angle-double-right"></i>
                            </a>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </article>
                </div>
              ))}
            </div>
            {/* <Pagination
              totalPosts={count}
              postPerPage={postPerPage}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
            /> */}
          </div>
        </div>
      </section>
    </>
  )
}

export default MainSubProducts
