import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { useNavigate, Link } from "react-router-dom";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import { loadStripe } from "@stripe/stripe-js";

import Icon from "@mdi/react";
import { mdiTrashCan } from "@mdi/js";

import { ShopContext } from "../context/shop-context";

function Cart() {
  const {
    removeFromCart,
    cartItems,
    products,
    totalPrice,
    cartCount,
    resetCart,
  } = useContext(ShopContext);

  console.log("cartitems : ", cartItems);
  console.log("cartCount : ", cartCount);

  const getProductDetails = (productId) => {
    return products.find((product) => product._id === productId);
  };

  const handleDelete = (itemId, packageQ) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Remove item!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        removeFromCart(itemId, packageQ);
      }
    });
  };

  const handleResetCart = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Reset cart!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        resetCart();
      }
    });
  };

  const makePayment = async () => {
    const stripe = await loadStripe(
      "pk_test_51PACYBP5v71h2SphipaDUnwvYTgOxQRhpSVGLsqBSISvwbC3QRnZC6xwO6DFlh7MdlWkEZ3LiPC1bZlvbqRPqVC700LeC30yXr"
    );

    try {
      const res = await fetch(
        `${process.env.REACT_APP_FURL}/api/payment/create-checkout-session`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            products: cartItems,
          }),
        }
      );

      const session = await res.json();

      if (res.status === 200) {
        await stripe.redirectToCheckout({ sessionId: session.id });
      } else {
        console.error("Failed to create Stripe checkout session");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      {" "}
      <section
        className="page-title-area breadcrumb-spacing"
        data-background="/assets/img/banner/breadcrumb-img.jpg"
        style={{
          backgroundImage: "url('/assets/img/banner/breadcrumb-img.jpg')",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-9">
              <div className="page-title-wrapper">
                <h3 className="page-title mb-25">Cart</h3>
                <div className="breadcrumb-menu">
                  <nav
                    aria-label="Breadcrumbs"
                    className="breadcrumb-trail breadcrumbs"
                  >
                    <ul className="trail-items">
                      <li className="trail-item trail-begin">
                        <a href="index.html">
                          <span>Home</span>
                        </a>
                      </li>
                      <li className="trail-item trail-end">
                        <span>Cart</span>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="content-inner shop-account">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="table-responsive">
                {cartCount > 0 ? (
                  <table className="table check-tbl">
                    <thead>
                      <tr>
                        <th>Product</th>
                        <th>Title</th>
                        <th>Package</th>
                        <th>Price</th>
                        <th>Quantity</th>
                        <th style={{ whiteSpace: "nowrap" }}>Sub Total</th>
                        <th>Remove</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries(cartItems).map(
                        ([
                          key,
                          { itemId, itemName, itemPrice, packageQ, quantity },
                        ]) => {
                          const product = getProductDetails(itemId);
                          if (!product) return null;

                          return (
                            <tr key={key}>
                              <td className="product-item-img">
                                <img
                                  src={
                                    process.env.REACT_APP_FURL +
                                    product.image[0]
                                  }
                                  style={{
                                    width: 150,
                                    height: 100,
                                  }}
                                  alt="/"
                                />
                              </td>
                              <td className="product-item-name">
                                {product.title}
                              </td>
                              <td className="product-item-quantity text-center">
                                {packageQ} pcs
                              </td>
                              <td className="product-item-price text-center">
                                $ {itemPrice}
                              </td>
                              <td className="product-item-quantity text-center">
                                {quantity}
                              </td>{" "}
                              <td className="product-item-price text-center">
                                $ {quantity * itemPrice}
                              </td>
                              <td className="product-item-close text-center text-danger">
                                <Link
                                  onClick={() => handleDelete(itemId, packageQ)}
                                >
                                  <Icon path={mdiTrashCan} size={1} />
                                </Link>
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                ) : (
                  <table className="table check-tbl">
                    <thead className="text-center">
                      <tr>
                        <th>
                          <h3>Shopping Cart</h3>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr style={{ height: "180px" }}>
                        <td>
                          <div className="row">
                            <center>
                              <h4 className="fw-bold col-9">
                                Your Cart is empty !
                                {/* <br /> Click shop to continue Shopping. */}
                              </h4>
                            </center>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                )}
              </div>
              <div className="row shop-form m-t30">
                <div className="col-md-6">
                  <div className="form-group">
                    <div className="input-group mb-0">
                      <input
                        name="dzEmail"
                        required="required"
                        type="text"
                        className="form-control"
                        placeholder="Coupon Code"
                        fdprocessedid="3hh4ro"
                      />
                      <div className="input-group-addon">
                        <button
                          name="submit"
                          value="Submit"
                          type="submit"
                          className="btn coupon"
                          fdprocessedid="ojxqza"
                        >
                          Apply Coupon
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 text-end">
                  <a className="btn btn-secondary" onClick={handleResetCart}>
                    RESET CART
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <h4 className="title mb15">Cart Total</h4>
              <div className="cart-detail">
                <a className="btn btn-outline-primary w-100 m-b20">
                  Bank Offer 5% Cashback
                </a>
                <div className="icon-bx-wraper style-4 m-b15">
                  <div className="icon-bx">
                    <i className="fa-solid fa-truck-fast"></i>
                  </div>
                  <div className="icon-content">
                    <span className="text-primary font-14">FREE</span>
                    <h6 className="dz-title">Enjoy The Product</h6>
                  </div>
                </div>
                <div className="icon-bx-wraper style-4 m-b30">
                  <div className="icon-bx">
                    <i className="fa-solid fa-cube"></i>
                  </div>
                  <div className="icon-content">
                    <h6 className="dz-title">Enjoy The Product</h6>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting
                    </p>
                  </div>
                </div>
                <div className="save-text">
                  <i className="fa-solid fa-circle-check"></i>
                  <span className="m-l10">
                    You will save ₹504 on this order
                  </span>
                </div>
                <table>
                  <tbody>
                    <tr className="total">
                      <td>
                        <h6 className="mb-0">Total</h6>
                      </td>
                      <td className="price">$ {totalPrice}</td>
                    </tr>
                  </tbody>
                </table>
                <Link
                  //  to="/shippingaddress"
                  onClick={makePayment}
                  className="btn btn-secondary w-100"
                >
                  PLACE ORDER
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Cart;
