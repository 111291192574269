import React, { createContext, useState, useEffect } from "react"

export const ShopContext = createContext(null)

const ShopContextProvider = (props) => {
  const [products, setProducts] = useState([])
  const [maincategory, setMaincategory] = useState([])
  const [count, setCount] = useState(0)
  const [cartItems, setCartItems] = useState(() => {
    const savedCart = localStorage.getItem("cart")
    return savedCart ? JSON.parse(savedCart) : {}
  })

  const [cartCount, setCartCount] = useState(0)
  const [totalPrice, setTotalPrice] = useState(0)

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cartItems))

    // Calculate total cart count
    const totalCount = Object.values(cartItems).reduce(
      (acc, item) => acc + item.quantity,
      0
    )
    setCartCount(totalCount)

    // Calculate subtotal price
    let totalValue = 0

    Object.entries(cartItems).forEach(
      ([key, { itemId, itemName, itemPrice, packageQ, quantity }]) => {
        const product = products.find((product) => product._id === itemId)
        if (product) {
          totalValue += itemPrice * quantity
        }
      }
    )

    setTotalPrice(totalValue)
  }, [cartItems, products])

  const addToCartCustom = (itemId, itemName, itemPrice, quantity, packageQ) => {
    const key = `${itemId}-${packageQ}`
    setCartItems((prev) => {
      const updatedCart = { ...prev }
      if (updatedCart[key]) {
        updatedCart[key] = {
          ...updatedCart[key],
          quantity: updatedCart[key].quantity + quantity,
        }
      } else {
        updatedCart[key] = { itemId, itemName, itemPrice, quantity, packageQ }
      }
      return updatedCart
    })
  }

  const removeFromCart = (itemId, packageQ) => {
    const key = `${itemId}-${packageQ}`
    setCartItems((prev) => {
      const updatedCart = { ...prev }
      if (updatedCart[key]) {
        delete updatedCart[key]
      }
      return updatedCart
    })
  }

  const resetCart = () => {
    setCartItems({})
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 100,
      behavior: "smooth",
    })
  }

  useEffect(() => {
    fetchProducts()
    fetchMaincategorydata()
  }, [])

  const fetchProducts = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_FURL + "/api/products/productdata"
      )
      if (!response.ok) {
        throw new Error("Failed to fetch products")
      }
      const data = await response.json()
      console.log("data", data)

      setProducts(data)
    } catch (error) {
      console.error("Error fetching products:", error.message)
    }
  }

  const fetchMaincategorydata = async (id) => {
    const res = await fetch(
      process.env.REACT_APP_FURL +
        "/api/categorys/parentcategory?id=" +
        `${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    const data = await res.json()
    if (res.status === 404 || !data) {
      alert("error")
    } else {
      console.log("Riyas", data)

      setMaincategory(data)
      console.log("get main-category and sub-category data", maincategory)
    }
  }

  const contextValue = {
    products,
    maincategory,
    cartItems,
    cartCount,
    totalPrice,
    addToCartCustom,
    removeFromCart,
    resetCart,
    scrollToTop,
  }

  return (
    <ShopContext.Provider value={contextValue}>
      {props.children}
    </ShopContext.Provider>
  )
}

export default ShopContextProvider
