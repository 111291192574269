import React from "react";

function EmergencyBanner() {
  return (
    <div className="bd-brand2 p-relative">
      <div
        className="container"
        style={{
          background:
            "linear-gradient(90.07deg, #892f86 32.43%, #3d2c88 58.79%, #1a133d 105.32%)",
        }}
      >
        <div
          className="row wow fadeInUp"
          data-wow-delay=".3s"
          style={{
            visibility: "visible",
            animationDelay: "0.3s",
            animationName: "fadeInUp",
            paddingTop: "4%",
          }}
        >
          <div className="col-xl-6">
            <div className="bd-cta__em-content mb-30">
              <div className="bd-cta__em-icon">
                <i className="flaticon-medal" style={{ color: "#fff" }}></i>
              </div>
              <div className="bd-cta__em-text">
                <h3>Do you have an emergency design service?</h3>
                <p style={{ color: "#fff" }}>
                  Need any kind printing & design service,Contact us.!
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="bd-cta__em-btn justify-content-xl-end two-btn mb-30">
              <a className="theme-btn white" href="#">
                <i className="fal fa-phone"></i> (416) 265-4605
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmergencyBanner;
