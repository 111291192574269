import React, { useState, useEffect, useContext } from "react";
// import axios from "axios";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Select from "react-select";
import { loadStripe } from "@stripe/stripe-js";

import { ShopContext } from "../context/shop-context";

function ShippingAddress() {
  const {
    addToCart,
    removeFromCart,
    DeleteFromCart,
    cartItems,
    products,
    shippingFee,
    totalPrice,
    subtotalPrice,
    cartCount,
    resetCart,
  } = useContext(ShopContext);

  let navigate = useNavigate();
  let params = useParams();

  let Userid = params.id;

  const [userData, setUserData] = useState();
  const [paymentMethod, setPaymentMethod] = useState();
  const [shipping, setShipping] = useState({
    user_id: "",
    number: "",
    name: "",
    address: "",
    state: "",
    zipcode: "",
    landmark: null,
    // paymentMethod: "",
  });

  let id, value;
  const handleInputs = (e) => {
    console.log(e);
    id = e.target.id;
    value = e.target.value;
    setShipping((prevShipping) => ({
      ...prevShipping,
      [id]: value,
    }));
  };

  const handlePayment = (selectedOption) => {
    if (selectedOption) {
      setPaymentMethod(selectedOption.value);
    }
  };

  const home = () => {
    navigate("/home");
  };

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_FURL}/api/auth/user/${params.id}`
        );
        if (response.status === 200) {
          const userData = await response.json();
          setUserData(userData);
          setShipping({
            user_id: userData._id,
            number: userData.number,
            name: userData.name,
            address: userData.address,
            state: userData.state,
            zipcode: userData.pincode,
            landmark: userData.landmark ? userData.landmark : "",
          });
        } else {
          console.error("Failed to fetch user data");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };
    fetchUser();
  }, []);

  const UpdateUser = async (e) => {
    const { name, address, state, zipcode, landmark } = shipping;
    console.log("shipping", shipping);
    try {
      const res = await fetch(
        `${process.env.REACT_APP_FURL}/api/auth/user/${params.id}`,
        {
          name: name,
          address: address,
          state: state,
          pincode: zipcode,
          landmark: landmark || "",
        },
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      if (res.status === 200) {
        console.log("User updated successfully");
      } else {
        console.error("Failed to update user");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const PostData = async () => {
    console.log("cartItems", cartItems);

    const res = await fetch(
      process.env.REACT_APP_FURL + "/api/cartitem/add_cart_item",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          cartItems: cartItems,
          user_id: Userid,
        }),
      }
    );

    const data = await res.json();
    if (res.status === 200) {
    }
  };

  const makePayment = async () => {
    const stripe = await loadStripe(
      "pk_test_51PACYBP5v71h2SphipaDUnwvYTgOxQRhpSVGLsqBSISvwbC3QRnZC6xwO6DFlh7MdlWkEZ3LiPC1bZlvbqRPqVC700LeC30yXr"
    );

    try {
      const res = await fetch(
        `${process.env.REACT_APP_FURL}/api/payment/create-checkout-session`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            products: cartItems,
          }),
        }
      );

      const session = await res.json();

      if (res.status === 200) {
        await stripe.redirectToCheckout({ sessionId: session.id });
      } else {
        console.error("Failed to create Stripe checkout session");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div>
      <section
        className="page-title-area breadcrumb-spacing"
        data-background="/assets/img/banner/breadcrumb-img.jpg"
        style={{
          backgroundImage: "url('/assets/img/banner/breadcrumb-img.jpg')",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-9">
              <div className="page-title-wrapper">
                <h3 className="page-title mb-25">Shipping Address</h3>
                <div className="breadcrumb-menu">
                  <nav
                    aria-label="Breadcrumbs"
                    className="breadcrumb-trail breadcrumbs"
                  >
                    <ul className="trail-items">
                      <li className="trail-item trail-begin">
                        <a href="index.html">
                          <span>Home</span>
                        </a>
                      </li>
                      <li className="trail-item trail-end">
                        <span>Shipping Address</span>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="row justify-content-center">
        <div className="col-md-2"></div>
        <div className="col-md-8">
          <div className="p-4">
            <div className="card overflow-hidden mt-2">
              <div className="card-body position-relative">
                <div className="p-4 mt-n5 bg-white card rounded pb-0">
                  <form className="row mt-2">
                    <div className="col-md-6 mb-3">
                      <label className="fs-14 mb-2" htmlFor="cnum">
                        Phone Number <span className="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="number"
                        value={shipping.number}
                        onChange={handleInputs}
                        disabled
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="fs-14 mb-2" htmlFor="name">
                        Name <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        value={shipping.name}
                        onChange={handleInputs}
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="fs-14 mb-2" htmlFor="address">
                        Address <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="address"
                        value={shipping.address}
                        onChange={handleInputs}
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="fs-14 mb-2" htmlFor="state">
                        State <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="state"
                        value={shipping.state}
                        onChange={handleInputs}
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="fs-14 mb-2" htmlFor="zipcode">
                        Zipcode <span className="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="zipcode"
                        value={shipping.zipcode}
                        onChange={handleInputs}
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="fs-14 mb-2" htmlFor="landmark">
                        Landmark (optional){" "}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="landmark"
                        value={shipping.landmark}
                        onChange={handleInputs}
                      />
                    </div>

                    <div className="col-md-6 ">
                      <div
                        className=" rounded pb-0 mt-3"
                        style={{
                          // border: "1px solid  #00776d",
                          border: "inherit",
                          padding: "30px",
                          marginBottom: "10px",
                        }}
                      >
                        <h4 className="" style={{ color: "#732f7c" }}>
                          <strong>
                            Mode of Payment
                            <img
                              src="/assets/img/extra/cash.png"
                              width="50px"
                              height="40px"
                              style={{ marginLeft: "20px" }}
                            ></img>
                          </strong>
                        </h4>

                        <div className="row">
                          <div className="col-md-12">
                            <Select
                              className="mb-4 mt-4 w-100"
                              name="paymentMethod"
                              value={shipping.paymentMethod}
                              onChange={handlePayment}
                              placeholder="Type of payment"
                              options={[
                                // { value: "", label: "Select Payment Method" },
                                // { value: "cod", label: "Cash on Delivery" },
                                {
                                  value: "online",
                                  label: "Online Payment",
                                },
                              ]}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div
                        className=" rounded pb-0 mt-3"
                        style={{
                          // border: "1px solid  #00776d",
                          padding: "30px",
                          marginBottom: "10px",
                        }}
                      >
                        <div class="shoping-cart-total row">
                          <h4 class="title-2" style={{ color: "#732f7c" }}>
                            <strong>
                              Cart Total <i className="mdi mdi-cart "></i>
                            </strong>
                          </h4>
                          <table class="table table-responsive mb-3">
                            <tbody>
                              <tr>
                                <td>Cart Count</td>
                                <td>
                                  <strong style={{ color: "#732f7c" }}>
                                    {cartCount}
                                  </strong>
                                </td>
                              </tr>
                              <tr>
                                <td>Order Total</td>
                                <td>
                                  <strong style={{ color: "#732f7c" }}>
                                    $ {totalPrice}
                                  </strong>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <div className="mt-3">
                      <center>
                        <button
                          className="theme-btn mb-3"
                          // type="submit"
                          onClick={makePayment}
                        >
                          Place Order
                        </button>
                      </center>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-2"></div>
        {/* end col */}
      </div>
    </div>
  );
}

export default ShippingAddress;
