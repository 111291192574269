import React from "react";

function AboutUs() {
  return (
    <section className="bd-about bd-about2 pt-95 pb-60" id="about">
      <div className="container">
        <div className="row wow fadeInUp" data-wow-delay=".3s">
          <div className="col-lg-12">
            <div className="bd-section__title mb-60">
              <span className="bd__subtitle">About Us</span>
              <h2 className="bd__title">Printing Solutions for Your Company</h2>
            </div>
          </div>
        </div>
        <div
          className="row bd-about2__wrapper wow fadeInUp"
          data-wow-delay=".5s"
        >
          <div className="col-xxl-6 col-xl-6 col-lg-6">
            <div className="bd-about2__img br-img-6 w-img mb-60">
              <p>
                At A-FAST PRINT, we take immense pride in our esteemed position
                as a trusted and established entity in the printing industry.
                With over 20 years of invaluable experience, we've garnered a
                reputation for delivering top-notch printing solutions to our
                cherished clients. Our unwavering commitment to excellence and
                relentless dedication to customer satisfaction distinguish us as
                the go-to printing partner for businesses and individuals alike.
                Trust us to bring your visions to life with precision, quality,
                and unwavering dedication that only A-FAST PRINT can provide
              </p>
            </div>
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6">
            <img src="/assets/img/about/about-4.jpg" width="100%" alt="About" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutUs;
