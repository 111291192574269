import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom"
import { lazy, Suspense, useLayoutEffect, React, useEffect } from "react"
import { ToastContainer, toast } from "react-toastify"

import "react-toastify/dist/ReactToastify.css"
import "./assets/css/style.css"
import "./App.css"

import Loader from "./components/Loader.jsx"
import Home from "./pages/Home.jsx"
import MainSubProducts from "./pages/MainSubProducts.jsx"
import SubProducts from "./pages/SubProducts.jsx"
import SubProductDesc from "./pages/SubProductDesc.jsx"
import Cart from "./pages/Cart.jsx"
import Checkout from "./pages/Checkout.jsx"
import ShippingAddress from "./pages/ShippingAddress.jsx"
import PaymentSuccess from "./pages/PaymentSuccess.jsx"
import PaymentFailure from "./pages/PaymentFailure.jsx"

const Layout = lazy(() => import("./pages/Layout.jsx"))

function App() {
  const Wrapper = ({ children }) => {
    const location = useLocation()
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0)
    }, [location.pathname])
    return children
  }
  return (
    <>
      <div>
        <ToastContainer />
        <Router>
          <Wrapper>
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <Routes>
                <Route
                  path="/"
                  element={<Layout />}
                  errorElement={
                    <div>
                      <Loader />
                    </div>
                  }
                >
                  <Route index element={<Home />} />

                  <Route
                    path="home"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            {" "}
                            <Loader />
                          </div>
                        }
                      >
                        <Home />
                      </Suspense>
                    }
                  />

                  <Route
                    path="main-sub-products/:id"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            {" "}
                            <Loader />
                          </div>
                        }
                      >
                        <MainSubProducts />
                      </Suspense>
                    }
                  />

                  <Route
                    path="sub-products/:id"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            {" "}
                            <Loader />
                          </div>
                        }
                      >
                        <SubProducts />
                      </Suspense>
                    }
                  />
                  <Route
                    path="sub-product-desc/:id"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            {" "}
                            <Loader />
                          </div>
                        }
                      >
                        <SubProductDesc />
                      </Suspense>
                    }
                  />

                  <Route
                    path="cart"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            {" "}
                            <Loader />
                          </div>
                        }
                      >
                        <Cart />
                      </Suspense>
                    }
                  />

                  <Route
                    path="/checkout"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            {" "}
                            <Loader />
                          </div>
                        }
                      >
                        <Checkout />
                      </Suspense>
                    }
                  />

                  <Route
                    // path="/shippingaddress/:id"
                    path="/shippingaddress"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            {" "}
                            <Loader />
                          </div>
                        }
                      >
                        <ShippingAddress />
                      </Suspense>
                    }
                  />

                  <Route
                    path="success"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            {" "}
                            <Loader />
                          </div>
                        }
                      >
                        <PaymentSuccess />
                      </Suspense>
                    }
                  />

                  <Route
                    path="failed"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            {" "}
                            <Loader />
                          </div>
                        }
                      >
                        <PaymentFailure />
                      </Suspense>
                    }
                  />

                  <Route
                    path="*"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        {/* <NotFound /> */}
                      </Suspense>
                    }
                  />
                </Route>
              </Routes>
            </Suspense>
          </Wrapper>
        </Router>
      </div>
    </>
  )
}

export default App
