// import React, { useEffect, useState, useContext } from "react";
// import { ShopContext } from "../context/shop-context";

// function Pagination({ totalPosts, postPerPage, setCurrentPage, currentPage }) {

//   const { scrollToTop } = useContext(ShopContext);
//   let pages = [];
//   for (let i = 1; i <= Math.ceil(totalPosts / postPerPage); i++) {
//     pages.push(i);
//   }

//   return (
//     <div className="row mt-20">
//       <div className="col-12">
//         <ul className="pagination_nav">
//         {pages.map((page, i) => {
//           <li>
//             <a href="#">
//               <i className="fa-solid fa-angles-left"></i>
//             </a>
//           </li>
//           <li className={page === currentPage ? "active":""}>
//             <a href="#">01</a>
//           </li>

//           <li>
//             <a href="#">
//               <i className="fa-solid fa-angles-right"></i>
//             </a>
//           </li>
//         </ul>
//       </div>
//     </div>
//   );
// }

// export default Pagination;

import React, { useEffect, useState, useContext } from "react";
import { ShopContext } from "../context/shop-context";

function Pagination({ totalPosts, postPerPage, setCurrentPage, currentPage }) {
  const { scrollToTop } = useContext(ShopContext);
  let pages = [];
  for (let i = 1; i <= Math.ceil(totalPosts / postPerPage); i++) {
    pages.push(i);
  }

  return (
    <div className="row mt-20">
      <div className="col-12">
        <ul className="pagination_nav">
          <li>
            <a
              href="#"
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <i className="fa-solid fa-angles-left"></i>
            </a>
          </li>
          {pages.map((page, i) => (
            <li key={i} className={page === currentPage ? "active" : ""}>
              <a
                href="#"
                onClick={() => {
                  setCurrentPage(page);
                  scrollToTop();
                }}
              >
                {page}
              </a>
            </li>
          ))}
          <li>
            <a
              href="#"
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={currentPage === Math.ceil(totalPosts / postPerPage)}
            >
              <i className="fa-solid fa-angles-right"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Pagination;
