import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";

const WorkPortfolio = () => {
  const isMobile = window.innerWidth <= 991;

  return (
    <section
      className="bd-case2__area fix p-relative pt-120 pb-120"
      data-wow-delay=".3s"
      id="portfolio"
    >
      <div className="container">
        <div
          className="row mb-40 wow fadeInUp"
          data-wow-delay=".3s"
          style={{
            visibility: "visible",
            animationDelay: "0.3s",
            animationName: "fadeInUp",
          }}
        >
          <div className="col-lg-8">
            <div className="bd-section__title mb-15">
              <span className="bd__subtitle">Portfolios</span>
              <h2 className="bd__title">Some of Our Works</h2>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="bd-case2__nav mb-30 bd-slider-nav d-flex align-items-center justify-content-lg-end">
              <div
                className="bd-case2-button-next"
                role="button"
                aria-label="Previous slide"
              >
                <i className="fal fa-long-arrow-left"></i>
              </div>
              <div
                className="bd-case2-button-prev"
                role="button"
                aria-label="Next slide"
              >
                <i className="fal fa-long-arrow-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="row justify-content-center wow fadeInUp"
        data-wow-delay=".5s"
        style={{
          visibility: "visible",
          animationDelay: "0.5s",
          animationName: "fadeInUp",
          //   padding: "0px 50px 0px 50px",
        }}
      >
        <div className="col-xxl-11">
          <div className="bd-case2__wrapper p-relative">
            <Swiper
              spaceBetween={30}
              slidesPerView={isMobile ? 1 : 3}
              loopedSlides={4}
              autoplay={{ delay: 3000, disableOnInteraction: false }}
              loop={true}
              navigation={{
                nextEl: ".bd-case2-button-next",
                prevEl: ".bd-case2-button-prev",
              }}
              modules={[Navigation, Autoplay]}
            >
              <SwiperSlide>
                <div
                  className="bd-case2__item"
                  style={
                    isMobile
                      ? {
                          width: "375px",
                          height: "240px",
                        }
                      : { width: "513px", height: "375px" }
                  }
                >
                  <div className="bd-case2__thumb w-img br-img-6">
                    <a href="#">
                      <img
                        src="assets/img/case/case-7.jpg"
                        alt="case-img"
                        style={
                          isMobile
                            ? {
                                width: "375px",
                                height: "205px",
                              }
                            : { width: "513px", height: "329px" }
                        }
                      />
                    </a>
                  </div>
                  <div className="bd-case2__content">
                    <div className="bd-case2__inner">
                      <span>Print, Design</span>
                      <h3>
                        <a href="#">Business Card Design</a>
                      </h3>
                    </div>
                    <div className="bd-case2__link">
                      <a href="#">
                        <i className="fal fa-long-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  className="bd-case2__item__active"
                  style={
                    isMobile
                      ? {
                          width: "375px",
                          height: "240px",
                        }
                      : { width: "513px", height: "375px" }
                  }
                >
                  <div className="bd-case2__thumb w-img br-img-6">
                    <a href="#">
                      <img
                        src="assets/img/case/case-8.jpg"
                        alt="case-img"
                        style={
                          isMobile
                            ? {
                                width: "375px",
                                height: "205px",
                              }
                            : { width: "513px", height: "329px" }
                        }
                      />
                    </a>
                  </div>
                  <div className="bd-case2__content">
                    <div className="bd-case2__inner">
                      <span>Print, Design</span>
                      <h3>
                        <a href="#">Brochure Card Design</a>
                      </h3>
                    </div>
                    <div className="bd-case2__link">
                      <a href="#">
                        <i className="fal fa-long-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  className="bd-case2__item"
                  style={
                    isMobile
                      ? {
                          width: "375px",
                          height: "240px",
                        }
                      : { width: "513px", height: "375px" }
                  }
                >
                  <div className="bd-case2__thumb w-img br-img-6">
                    <a href="#">
                      <img
                        src="assets/img/case/case-9.jpg"
                        alt="case-img"
                        style={
                          isMobile
                            ? {
                                width: "375px",
                                height: "205px",
                              }
                            : { width: "513px", height: "329px" }
                        }
                      />
                    </a>
                  </div>
                  <div className="bd-case2__content">
                    <div className="bd-case2__inner">
                      <span>Print, Design</span>
                      <h3>
                        <a href="#">Invitation Card Design</a>
                      </h3>
                    </div>
                    <div className="bd-case2__link">
                      <a href="#">
                        <i className="fal fa-long-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div
                  className="bd-case2__item"
                  style={
                    isMobile
                      ? {
                          width: "375px",
                          height: "240px",
                        }
                      : { width: "513px", height: "375px" }
                  }
                >
                  <div className="bd-case2__thumb w-img br-img-6">
                    <a href="#">
                      <img
                        src="assets/img/case/case-7.jpg"
                        alt="case-img"
                        style={
                          isMobile
                            ? {
                                width: "375px",
                                height: "205px",
                              }
                            : { width: "513px", height: "329px" }
                        }
                      />
                    </a>
                  </div>
                  <div className="bd-case2__content">
                    <div className="bd-case2__inner">
                      <span>Print, Design</span>
                      <h3>
                        <a href="#">Business Card Design</a>
                      </h3>
                    </div>
                    <div className="bd-case2__link">
                      <a href="#">
                        <i className="fal fa-long-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  className="bd-case2__item__active"
                  style={
                    isMobile
                      ? {
                          width: "375px",
                          height: "240px",
                        }
                      : { width: "513px", height: "375px" }
                  }
                >
                  <div className="bd-case2__thumb w-img br-img-6">
                    <a href="#">
                      <img
                        src="assets/img/case/case-8.jpg"
                        alt="case-img"
                        style={
                          isMobile
                            ? {
                                width: "375px",
                                height: "205px",
                              }
                            : { width: "513px", height: "329px" }
                        }
                      />
                    </a>
                  </div>
                  <div className="bd-case2__content">
                    <div className="bd-case2__inner">
                      <span>Print, Design</span>
                      <h3>
                        <a href="#">Brochure Card Design</a>
                      </h3>
                    </div>
                    <div className="bd-case2__link">
                      <a href="#">
                        <i className="fal fa-long-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  className="bd-case2__item"
                  style={
                    isMobile
                      ? {
                          width: "375px",
                          height: "240px",
                        }
                      : { width: "513px", height: "375px" }
                  }
                >
                  <div className="bd-case2__thumb w-img br-img-6">
                    <a href="#">
                      <img
                        src="assets/img/case/case-9.jpg"
                        alt="case-img"
                        style={
                          isMobile
                            ? {
                                width: "375px",
                                height: "205px",
                              }
                            : { width: "513px", height: "329px" }
                        }
                      />
                    </a>
                  </div>
                  <div className="bd-case2__content">
                    <div className="bd-case2__inner">
                      <span>Print, Design</span>
                      <h3>
                        <a href="#">Invitation Card Design</a>
                      </h3>
                    </div>
                    <div className="bd-case2__link">
                      <a href="#">
                        <i className="fal fa-long-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WorkPortfolio;
