import React, { useState, useEffect, useContext } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

import HomeBanner from "../components/HomeBanner";

function PaymentFailure() {
  let navigate = useNavigate();
  const home = () => {
    navigate("/home");
  };

  useEffect(() => {
    const handleSucces = () => {
      Swal.fire({
        title: "Payment Failed !",
        text: " Unable to place order!",
        icon: "error",
        showCancelButton: false,
        confirmButtonColor: "#bf2231",
        cancelButtonColor: "#d33",
        confirmButtonText: "home",
      }).then(async (result) => {
        if (result.isConfirmed) {
          home();
        }
      });
    };
    handleSucces();
  }, []);

  return (
    <div>
      <HomeBanner />
    </div>
  );
}

export default PaymentFailure;
