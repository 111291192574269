import React from "react";

function WorkProcess() {
  return (
    <section
      className="bd-work bd-work__80 white-bg-2 pb-90 pt-200"
      // data-background="/assets/img/work/paper-line.png"
      style={{ backgroundImage: `url(/assets/img/work/paper-line.png)` }}
    >
      <div className="bd-work__wrapper">
        <div className="container">
          <div
            className="row justify-content-center wow fadeInUp"
            data-wow-delay=".3s"
          >
            <div className="col-md-7">
              <div className="bd-section__title mb-45 text-center">
                <span className="bd__subtitle">Our Process</span>
                <h2 className="bd__title">How We Work Here</h2>
              </div>
            </div>
          </div>
          <div
            className="bd-work__item-wrapper wow fadeInUp"
            data-wow-delay=".5s"
          >
            <div className="row">
              <div className="col-xl-3 col-lg-4 col-sm-6">
                <div className="bd-work__item p-relative bd-hover-top">
                  <div className="bd-work__step">
                    <h2>01</h2>
                    <span>Step</span>
                  </div>
                  <div className="bd-work__icon">
                    <img src="/assets/img/work/work-1.png" alt="Work" />
                  </div>
                  <div className="bd-work__text">
                    <p>Quality service near from you</p>
                    <h3>Prepress operations encompass</h3>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6">
                <div className="bd-work__item p-relative bd-hover-top">
                  <div className="bd-work__step">
                    <h2>02</h2>
                    <span>Step</span>
                  </div>
                  <div className="bd-work__icon">
                    <img src="/assets/img/work/work-2.png" alt="Work" />
                  </div>
                  <div className="bd-work__text">
                    <p>Quality service near from you</p>
                    <h3>The image or printing area is raised</h3>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6">
                <div className="bd-work__item p-relative bd-hover-top">
                  <div className="bd-work__step">
                    <h2>03</h2>
                    <span>Step</span>
                  </div>
                  <div className="bd-work__icon">
                    <img src="/assets/img/work/work-3.png" alt="Work" />
                  </div>
                  <div className="bd-work__text">
                    <p>Quality service near from you</p>
                    <h3>The intaglio process, the nonprinting</h3>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6">
                <div className="bd-work__item p-relative bd-hover-top">
                  <div className="bd-work__step">
                    <h2>04</h2>
                    <span>Step</span>
                  </div>
                  <div className="bd-work__icon">
                    <img src="/assets/img/work/work-4.png" alt="Work" />
                  </div>
                  <div className="bd-work__text">
                    <p>Quality service near from you</p>
                    <h3>Prepress operations typically involve</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WorkProcess;
