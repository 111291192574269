import React from "react";

function ServicesList() {
  return (
    <section className="bd-services2 bd-services2__90" id="services">
      <div className="container wow fadeInUp" data-wow-delay=".3s">
        <div className="row">
          <div className="col-xl-2 col-md-3 col-6">
            <div className="bd-services2__item mb-30 bd-shadow border-radius-6 text-center">
              <div className="bd-services2__img">
                <img src="/assets/img/services/services-img.png" alt="img" />
              </div>
              <div className="bd-services2__icon">
                <i className="flaticon-business-cards"></i>
              </div>
              <h6 className="bd-services2__title">
                <a href="#">Business Cards</a>
              </h6>
            </div>
          </div>
          <div className="col-xl-2 col-md-3 col-6">
            <div className="bd-services2__item mb-30 bd-shadow border-radius-6 text-center">
              <div className="bd-services2__img">
                <img src="/assets/img/services/services-img.png" alt="img" />
              </div>
              <div className="bd-services2__icon">
                <i className="fa-solid fa-book-open"></i>
              </div>
              <h6 className="bd-services2__title">
                <a href="#">Brochures & Flyers</a>
              </h6>
            </div>
          </div>
          <div className="col-xl-2 col-md-3 col-6">
            <div className="bd-services2__item mb-30 bd-shadow border-radius-6 text-center">
              <div className="bd-services2__img">
                <img src="/assets/img/services/services-img.png" alt="img" />
              </div>
              <div className="bd-services2__icon">
                <i className="fa-solid fa-photo-film"></i>
              </div>
              <h6 className="bd-services2__title">
                <a href="#">Photo Copy & Frame</a>
              </h6>
            </div>
          </div>
          <div className="col-xl-2 col-md-3 col-6">
            <div className="bd-services2__item mb-30 bd-shadow border-radius-6 text-center">
              <div className="bd-services2__img">
                <img src="/assets/img/services/services-img.png" alt="img" />
              </div>
              <div className="bd-services2__icon">
                <i className="flaticon-t-shirt"></i>
              </div>
              <h6 className="bd-services2__title">
                <a href="#">T-Shirts & Mug Print</a>
              </h6>
            </div>
          </div>
          <div className="col-xl-2 col-md-3 col-6">
            <div className="bd-services2__item mb-30 bd-shadow border-radius-6 text-center">
              <div className="bd-services2__img">
                <img src="/assets/img/services/services-img.png" alt="img" />
              </div>
              <div className="bd-services2__icon">
                <i className="flaticon-printer"></i>
              </div>
              <h6 className="bd-services2__title">
                <a href="#">Invitation Cards</a>
              </h6>
            </div>
          </div>
          <div className="col-xl-2 col-md-3 col-6">
            <div className="bd-services2__item mb-30 bd-shadow border-radius-6 text-center">
              <div className="bd-services2__img">
                <img src="/assets/img/services/services-img.png" alt="img" />
              </div>
              <div className="bd-services2__icon">
                <i className="fa-solid fa-note-sticky"></i>
              </div>
              <h6 className="bd-services2__title">
                <a href="#">Vinyl sign installation</a>
              </h6>
            </div>
          </div>
          <div className="col-xl-2 col-md-3 col-6">
            <div className="bd-services2__item mb-30 bd-shadow border-radius-6 text-center">
              <div className="bd-services2__img">
                <img src="/assets/img/services/services-img.png" alt="img" />
              </div>
              <div className="bd-services2__icon">
                <i className="flaticon-billboard"></i>
              </div>
              <h6 className="bd-services2__title">
                <a href="#">Stickers & Envelope</a>
              </h6>
            </div>
          </div>
          <div className="col-xl-2 col-md-3 col-6">
            <div className="bd-services2__item mb-30 bd-shadow border-radius-6 text-center">
              <div className="bd-services2__img">
                <img src="/assets/img/services/services-img.png" alt="img" />
              </div>
              <div className="bd-services2__icon">
                <i className="flaticon-branding"></i>
              </div>
              <h6 className="bd-services2__title">
                <a href="#">Wallpaper Printing</a>
              </h6>
            </div>
          </div>
          <div className="col-xl-2 col-md-3 col-6">
            <div className="bd-services2__item mb-30 bd-shadow border-radius-6 text-center">
              <div className="bd-services2__img">
                <img src="/assets/img/services/services-img.png" alt="img" />
              </div>
              <div className="bd-services2__icon">
                <i className="fa-solid fa-window-restore"></i>
              </div>
              <h6 className="bd-services2__title">
                <a href="#">Window sign </a>
              </h6>
            </div>
          </div>
          <div className="col-xl-2 col-md-3 col-6">
            <div className="bd-services2__item mb-30 bd-shadow border-radius-6 text-center">
              <div className="bd-services2__img">
                <img src="/assets/img/services/services-img.png" alt="img" />
              </div>
              <div className="bd-services2__icon">
                <i className="fa-solid fa-toilet-paper"></i>
              </div>
              <h6 className="bd-services2__title">
                <a href="#">Roll-up Banner </a>
              </h6>
            </div>
          </div>
          <div className="col-xl-2 col-md-3 col-6">
            <div className="bd-services2__item mb-30 bd-shadow border-radius-6 text-center">
              <div className="bd-services2__img">
                <img src="/assets/img/services/services-img.png" alt="img" />
              </div>
              <div className="bd-services2__icon">
                <i className="fa-solid fa-book"></i>
              </div>
              <h6 className="bd-services2__title">
                <a href="#">Book & Notepads </a>
              </h6>
            </div>
          </div>
          <div className="col-xl-2 col-md-3 col-6">
            <div className="bd-services2__item mb-30 bd-shadow border-radius-6 text-center">
              <div className="bd-services2__img">
                <img src="/assets/img/services/services-img.png" alt="img" />
              </div>
              <div className="bd-services2__icon">
                <i className="fa-solid fa-calendar-days"></i>
              </div>
              <h6 className="bd-services2__title">
                <a href="#">Calendar Making </a>
              </h6>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ServicesList;
