import React, { useState, useEffect, useContext } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

import HomeBanner from "../components/HomeBanner";

function PaymentSuccess() {
  let navigate = useNavigate();
  const home = () => {
    navigate("/home");
  };

  useEffect(() => {
    const handleSucces = () => {
      Swal.fire({
        title: "Payment Success !",
        text: "Order placed successfully. your order id is xxxxxx!",
        icon: "success",
        showCancelButton: false,
        confirmButtonColor: "#0d775e",
        cancelButtonColor: "#d33",
        confirmButtonText: "home",
      }).then(async (result) => {
        if (result.isConfirmed) {
          home();
        }
      });
    };
    handleSucces();
  }, []);
  return (
    <div>
      <HomeBanner />
    </div>
  );
}

export default PaymentSuccess;
