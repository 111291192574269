import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

import { ShopContext } from "../context/shop-context";

function ServicesCard() {
  let params = useParams();
  const { maincategory } = useContext(ShopContext);

  const [activeProducts, setActiveProducts] = useState([]);

  useEffect(() => {
    setActiveProducts(maincategory);
  }, [maincategory]);

  return (
    <section className="bd-blog grey-bg-2 pt-120 pb-130">
      <div className="container">
        <div
          className="row justify-content-center wow fadeInUp"
          data-wow-delay=".3s"
          style={{
            visibility: "visible",
            animationDelay: "0.3s",
            animationName: "fadeInUp",
          }}
        ></div>
        <div className="bd-blog__wrapper2 grey-bg-2">
          <div
            className="row wow fadeInUp"
            data-wow-delay=".5s"
            style={{
              visibility: "visible",
              animationDelay: "0.5s",
              animationName: "fadeInUp",
            }}
          >
            {activeProducts.map((prd) => (
              <div className="col-xl-4 col-md-6" key={prd._id}>
                <article>
                  <div className="bd-blog__item p-relative mb-40">
                    <Link to={`/main-sub-products/${prd._id}`}>
                      <div className="bd-blog__thum w-img">
                        <a href={process.env.REACT_APP_FURL + prd.icon}>
                          <img
                            src={process.env.REACT_APP_FURL + prd.icon}
                            alt="Blog"
                          />
                        </a>
                      </div>
                      <div className="bd-blog__content white-bg">
                        <div className="bd-blog__category">
                          <a href="">Our Services</a>
                        </div>
                        <h3 className="bd-blog__title mb-30">
                          <a href="">{prd.title}</a>
                        </h3>
                        <p>{prd.slug}</p>
                        <div className="bd-blog__btn">
                          <a href="">
                            read more{" "}
                            <i className="fal fa-angle-double-right"></i>
                          </a>
                        </div>
                      </div>
                    </Link>
                  </div>
                </article>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default ServicesCard;
